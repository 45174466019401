/**
 * Extracts an hotel product from a given booking offer
 *
 * @param bookedOffer The offer containing the P2P data
 * @param booking - The booking data from the API schema.
 * @returns The hotel product
 */
export function transformBookingProductHotel(
    bookedOffer: ApiSchema['BookedOffer'],
    booking: ApiSchema['Booking'],
): ProductHotel | undefined {
    const passengers = booking.passengers;
    const remark = booking.customerRemark;
    const accommodation = bookedOffer.accommodations?.[0];
    const productConfig = getHotelProductConfig();

    if (!productConfig) {
        return undefined;
    }

    const offerPassengers = passengers.filter(p => bookedOffer.accommodations?.some(a => a.passengerIds.includes(p.id))) ?? [];
    const passengerRefs = offerPassengers.map(p => p.externalRef);
    const dateFrom = accommodation?.validFrom;
    const dateUntil = accommodation?.validUntil;
    const name = accommodation?.accommodationDetails?.name ?? '';

    // Create an offer with the corresponding offer parts for each room
    const offer = transformBookingOfferHotel(bookedOffer, passengers);

    const accDetails = accommodation?.accommodationDetails;
    const details = {
        id: accDetails?.id ?? '',
        name: accDetails?.name,
        address: {
            street: accDetails?.address?.street ?? '',
            countryCode: accDetails?.address?.countryCode ?? '',
            postCode: accDetails?.address?.postCode ?? '',
            city: accDetails?.address?.city ?? '',
        },
        phoneNumber: accDetails?.phoneNumber,
        email: accDetails?.email,
        location: {
            latitude: Number.parseFloat(accDetails?.location?.latitude ?? ''),
            longitude: Number.parseFloat(accDetails?.location?.longitude ?? ''),
        },
    } satisfies HotelDetails;

    return {
        config: productConfig,
        name,
        dateFrom: zurichDate(dateFrom),
        dateUntil: zurichDate(dateUntil),
        rooms: [],
        passengerRefs,
        details,
        offer,
        remark: remark ?? '',
    } satisfies ProductHotel;
}
