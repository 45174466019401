export default {
    key: 'hotel',
    type: 'hotel',
    name: 'core.productNames.hotel',
    disablePassengers: true,
    isUsingReductionCards: false,
    isUsingKeyCards: false,
    upsells: [],

    steps: [],

    stepsUpsell: [
        {
            routeName: 'upselling-hotel-steps-step-1-date',
            name: 'upselling.hotel.steps.step1',
        },
        {
            routeName: 'upselling-hotel-steps-step-2-residents',
            name: 'upselling.hotel.steps.step2',
        },
        {
            routeName: 'upselling-hotel-step-3-hotel-list',
            name: 'upselling.hotel.steps.step3',
            hideInStepper: true,
        },
        {
            routeName: 'upselling-hotel-step-4-hotel-details',
            name: 'upselling.hotel.steps.step4',
            hideInStepper: true,
        },
    ],
} satisfies ProductConfigHotel;
