/**
 * Transforms a booking response into a booking object
 *
 * @param response The API response
 * @returns The booking object
 */
export function transformBooking(response: ApiSchema['BookingResponse']): Booking {
    const booking = response.booking;
    return {
        id: booking?.id ?? '',
        bookingNo: booking?.bookingNo ?? '',
        openAmount: booking?.openAmount ?? { amount: 0, currency: 'CHF' },
        confirmedPrice: booking?.confirmedPrice ?? { amount: 0, currency: 'CHF' },
        purchaser: booking?.purchaser,
        confirmationTimeLimit: zurichDate(booking?.confirmationTimeLimit),
        hasFullfilled: !!booking?.fulfillments && booking?.fulfillments.length > 0,
        orderDate: zurichDate(booking?.createdOn),
        paymentMethods: extractPaymentMethods(booking),
        remark: booking?.customerRemark ?? '',
    };
}

function extractPaymentMethods(booking?: ApiSchema['Booking']): string[] {
    return booking?.paymentMethods
        ?.filter(pm => pm.originalPaymentId === null && pm.state === 'SETTLED')
        .map(pm => pm.paymentMethod ?? '') ?? [];
}
