<script setup lang="ts">
const { t: $t } = useI18n()

import type { NuxtError } from '#app';

/**
 * Global Fullscreen Error Page
 */

const { error } = defineProps<{
    error: NuxtError;
}>();
const { href } = useRequestURL();
const title = error.statusCode === 404 ? $t('core.errorMessages.404') : $t('core.errorMessages.500');

function handleError() {
    clearError({ redirect: '/' });
}

onMounted(() => {
    analyticsTrackError(String(error.statusCode), href, title);
});
</script>

<template>
    <div class="flex min-h-dvh items-center justify-center bg-brand-b px-container-gap text-white">
        <TheLogo class="absolute left-container-gap top-5 md:top-7" />
        <PaneProse class="text-center" data-sentry-unmask>
            <template v-if="error.statusCode === 404">
                <h1 class="mb-2">
                    {{ title }}
                </h1>
            </template>

            <template v-else>
                <h1 class="mb-2">
                    {{ title }}
                </h1>
                <p class="">
                    {{ error?.message }}
                </p>
            </template>
            <BaseButton class="mx-auto mt-8 block" @click="handleError">
                {{ $t('core.buttons.toStart') }}
            </BaseButton>
        </PaneProse>
    </div>
</template>
