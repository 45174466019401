export default {
    key: 'swa',
    type: 'activity',
    name: 'core.productNames.swa',
    categoryId: '8fe8dfc0-8d5b-4af1-9d20-6d2f3b271872',
    isUsingReductionCards: false,
    isUsingKeyCards: false,
    isIntraDay: true,
    upsells: [],

    steps: [
        {
            routeName: 'offerFlow-swa-steps-step-1-activity',
            name: 'offerFlow.pages.swa.steps.step1',
        },
        {
            routeName: 'offerFlow-swa-steps-step-2-date',
            name: 'offerFlow.pages.swa.steps.step2',
        },
        {
            routeName: 'offerFlow-swa-steps-step-3-time',
            name: 'offerFlow.pages.swa.steps.step3',
        },
        {
            routeName: 'offerFlow-swa-steps-step-4-passengers',
            name: 'offerFlow.pages.swa.steps.step4',
        },
        {
            routeName: 'offerFlow-swa-steps-step-5-summary',
            name: 'offerFlow.pages.swa.steps.step5',
            isUpsellStep: true,
        },
    ],

    stepsUpsell: [
        {
            routeName: 'upselling-swa-steps-step-1-activity',
            name: 'offerFlow.pages.swa.steps.step1',
        },
        {
            routeName: 'upselling-swa-steps-step-2-date',
            name: 'offerFlow.pages.swa.steps.step2',
        },
        {
            routeName: 'upselling-swa-steps-step-3-time',
            name: 'offerFlow.pages.swa.steps.step3',
            nextLabel: 'core.buttons.save',
        },
    ],

} satisfies ProductConfigActivity;
