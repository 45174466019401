import { isAfter } from 'date-fns';

/**
 * Transforms the refundable activities from the booking and offer data.
 *
 * @param booking - The booking data from the API schema.
 * @param product - The previously transformed activity product
 * @returns An array of transformed activity refundables.
 */
export function transformRefundablesActivity(
    booking: ApiSchema['Booking'],
    product: ProductActivity,
): RefundableActivity[] {
    const refundables: RefundableActivity[] = [];
    const offer = booking.bookedOffers?.find(o => o.offerId === product.offer?.offerId);

    // Next, get all fulfillments of the booking that correspond to activities
    const fulfilledActivities = booking?.fulfillments
        // Only look at the entries in fulfillments which correspond to an activity offer
        // By looking at the ids within the booking parts of the fulfillment
        // and then checking if those ids correspond to an activity — an id within the activities of an offer
        ?.filter(fulfillment => fulfillment.bookingParts?.some(
            bookingPart => offer?.activities?.some(activity => activity.id === bookingPart.id),
        ));
    // Loop through the filtered activity fulfillments
    fulfilledActivities?.forEach((fulfillment) => {
        // Get the activity based on the id of the fulfillments' booking parts
        const activity = offer?.activities?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));
        const passenger = booking?.passengers.find(p => activity?.passengerIds?.includes(p.id));

        // Determine if the product is still eligible for refund
        const isOfferExpired = isAfter(zurichDate(), zurichDate(activity?.validUntil));
        const isRefundDisabled = false;
        let isRefundExpired = false;
        let refundDeadline;

        if (activity?.afterSaleConditions && activity.afterSaleConditions.length > 0) {
            // Find the first condition where the 'condition' is 'REFUND'
            const refundCondition = activity.afterSaleConditions.find(
                condition => condition.condition === 'REFUND',
            );

            // Check if the current date is before the 'validUntil' date and after the 'validFrom' date
            isRefundExpired = !refundCondition
            || (refundCondition?.validUntil !== null && isAfter(zurichDate(), zurichDate(refundCondition?.validUntil)))
            || (refundCondition?.validFrom !== null && isAfter(zurichDate(refundCondition?.validFrom), zurichDate()));

            refundDeadline = zurichDate(refundCondition?.validUntil);
        }

        // Finally, transform and add the object to the refunds
        refundables.push({
            id: fulfillment.id,
            type: 'activity',
            status: activity?.status ?? 'FULFILLED',
            product,
            isOfferExpired,
            passenger: {
                ref: passenger?.externalRef ?? '',
                firstName: passenger?.detail?.firstName ?? '',
                lastName: passenger?.detail?.lastName ?? '',
                dateOfBirth: zurichDate(passenger?.dateOfBirth),
            },
            price: activity?.price ?? { amount: 0, currency: 'CHF' },
            refundDeadline,
            isRefundExpired,
            isRefundDisabled,
            groupPerPassenger: false,
        } satisfies RefundableActivity);
    });

    return refundables;
}
