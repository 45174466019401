export default {
    key: 'joch',
    type: 'p2p',
    name: 'core.productNames.joch',
    destinations: [{
        ref: 'urn:uic:stn:8507364',
        name: 'Jungfrau Joch - Top of Europe',
    }],
    isRoundtrip: true,
    canSwitchDirection: false,
    isUsingReductionCards: true,
    isUsingKeyCards: false,
    hasEPR: true,
    hasJochVariants: true,
    upsells: [],
    departureTimeIteration: 30,
    departureTimeDefaultMin: 420,
    stayDurationIteration: 30,
    stayDurationPopular: 150,
    stayDurationMin: 30,
    maxNumberOfPeopleGroupTariff: 10,

    steps: [
        {
            routeName: 'offerFlow-joch-steps-step-1-date',
            name: 'offerFlow.pages.joch.steps.step1',
        },
        {
            routeName: 'offerFlow-joch-steps-step-2-number-of-people',
            name: 'offerFlow.pages.joch.steps.step2',
        },
        {
            routeName: 'offerFlow-joch-steps-step-3-trip-selection',
            name: 'offerFlow.pages.joch.steps.step3',
        },
        {
            routeName: 'offerFlow-joch-steps-step-4-trip-overview',
            name: 'offerFlow.pages.joch.steps.step4',
            hideInStepper: true,
        },
        {
            routeName: 'offerFlow-joch-steps-step-5-passengers',
            name: 'offerFlow.pages.joch.steps.step5',
        },
        {
            routeName: 'offerFlow-joch-steps-step-6-summary',
            name: 'offerFlow.pages.joch.steps.step6',
            isUpsellStep: true,
        },
    ],

    stepsUpsell: [
        {
            routeName: 'upselling-joch-steps-step-1-date',
            name: 'offerFlow.pages.joch.steps.step1',
        },
        {
            routeName: 'upselling-joch-steps-step-2-trip-selection',
            name: 'offerFlow.pages.joch.steps.step3',
        },
        {
            routeName: 'upselling-joch-steps-step-3-trip-overview',
            name: 'offerFlow.pages.joch.steps.step4',
            hideInStepper: true,
            nextLabel: 'core.buttons.save',
        },
    ],

} satisfies ProductConfigP2P;
