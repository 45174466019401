/**
 * Global error handler that catches all unhandled errors.
 *
 * Please use the handleError() util for any actual handling, as its also used
 * for directly handling expected errors.
 *
 * You can create nuxt errors that are caught by this handler by throwing an object with the following structure:
 * In the data object you can specify a toastType to show a specific toast message.
 *
 * ```
 *  throw createError({
 *      message: 'Things went off the rails',
 *      data: {
 *          toastType: 'specific'
 *      }
 *  })
 * ```
 *
 * For handled errors, you might also rather use the handleError() util directly.
 * See: https://nuxt.com/docs/getting-started/error-handling
 */
export default defineNuxtPlugin((nuxtApp) => {
    // Handles clientside, non-fatal errors
    // Both unhandled and custom thrown (createError({ fatal: false }))
    nuxtApp.vueApp.config.errorHandler = (error: any, instance, info) => {
        handleError(error, { instance, info }, error?.data?.toastType ?? false);
    };

    // Handles fatal errors
    // Both unhandled and custom thrown (createError({ fatal: false }))
    nuxtApp.hook('app:error', (error) => {
        handleError(error, { }, false);
    });
});
