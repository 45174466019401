/**
 * Creates a custom $fetch instance with the correct headers for the TOT Backend API.
 * See: https://nuxt.com/docs/guide/recipes/custom-usefetch#custom-fetch
 *
 * Make sure you use the API helper methods or always use $api() instead of $fetch() to call the backend API.
 */
export default defineNuxtPlugin(() => {
    const runtimeConfig = useRuntimeConfig();
    const locale = (useNuxtApp().$i18n?.locale?.value ?? 'de') as string;

    const $api = $fetch.create({
        // Docs: https://preview.jts.jungfrau.ch/documentation/travelbooking-api-doc.html
        baseURL: runtimeConfig.public.apiBase,
        headers: {
            'Requestor': runtimeConfig.public.apiRequestor,
            'Accept-Language': locale,
        },
    });

    return {
        provide: {
            api: $api,
        },
    };
});
