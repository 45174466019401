import { transformRefundablesActivity } from '~esav/api/transformers/refundables/transformRefundablesActivity';
import { transformRefundablesAncillary } from '~esav/api/transformers/refundables/transformRefundablesAncillary';
import { transformRefundablesHotel } from '~esav/api/transformers/refundables/transformRefundablesHotel';
import { transformRefundablesP2P } from '~esav/api/transformers/refundables/transformRefundablesP2P';

/**
 * Transform a booking response and return the refundable offers.
 *
 * @param response The full api response
 * @param products The already transformed products object
 * @returns The refundable fulfillments as "Refundables"
 */
export function transformRefundables(response: ApiSchema['BookingResponse'], products: Products): Refundables {
    const booking = response.booking;
    const bookedOffers = booking?.bookedOffers;

    // Return empty object if the booking or the offers are empty
    if (!booking || !bookedOffers) {
        return {};
    }

    const refundables = Object.values(products).reduce<Refundables>((acc, product) => {
        if (product.config.type === 'activity') {
            const refundables = transformRefundablesActivity(booking, product as ProductActivity);
            add(acc, product.config.key, refundables);
        }
        else if (product.config.type === 'p2p') {
            const refundables = transformRefundablesP2P(booking, product as ProductP2P);
            add(acc, product.config.key, refundables);
        }
        else if (product.config.type === 'hotel') {
            const refundables = transformRefundablesHotel(booking, product as ProductHotel);
            add(acc, product.config.key, refundables);
        }
        return acc;
    }, {}) ?? {};

    const offersAncillary = bookedOffers?.filter(offer => offer.ancillaries?.length);
    offersAncillary?.forEach((offer) => {
        const refundablesAncillary = transformRefundablesAncillary(booking, offer);
        const key = refundablesAncillary[0]?.productKey;
        add(refundables, key, refundablesAncillary);
    });

    return refundables;
}

/**
 *
 */
function add(acc: Refundables, key: ProductKey | undefined, refundables: Refundable[]) {
    if (!key || !refundables.length) {
        return;
    }
    if (!acc[key]) {
        acc[key] = [];
    }
    acc[key].push(...refundables);
}
