<script setup  lang="ts">
const { t: $t } = useI18n()

const { isLinked = true } = defineProps<{
    isLinked?: boolean;
}>();
const runtimeConfig = useRuntimeConfig();
const { openConfirmDialog } = useConfirmActionDialog();

function ciao() {
    if (!isLinked) {
        return;
    }
    if (runtimeConfig.public.enableLandingpage) {
        navigateTo('/');
    }
    else {
        openConfirmDialog(
            $t('core.confirmLeaveDialog.heading'),
            $t('core.confirmLeaveDialog.body'),
            () => {
                navigateTo('/');
            },
            $t('core.confirmLeaveDialog.confirmLabel'),
            () => {},
            $t('core.confirmLeaveDialog.cancelLabel'),
        );
    }
};
</script>

<template>
    <button
        class="absolute left-container-gap top-5 z-20"
        :class="isLinked ? 'cursor-pointer' : 'cursor-default'"
        @click="ciao"
    >
        <img
            src="~~core/assets/images/jungfrau-logo.svg"
            class="
                w-[43px]
                sm:w-[68px]
                md:w-[80px]
                print:w-[36px]
            "
            alt="Jungfrau - Top of Europe"
            width="80px"
            height="56px"
        >
    </button>
</template>
