/**
 * Stores a timestamp on every page switch in the local storage.
 * This is used to determine if the user has been inactive for a certain time.
 */
export default defineNuxtRouteMiddleware(() => {
    if (import.meta.client) {
        try {
            localStorage.setItem('tot_last_interaction', new Date().toISOString());
        }
        catch (error) {}
    }
});
