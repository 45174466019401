import { transformBookingProducts } from '~core/api/transformers/booking/bookingProducts/transformBookingProducts';
import { transformBooking } from '~core/api/transformers/booking/transformBooking';
import { transformRefundables } from '~esav/api/transformers/refundables/transformRefundables';
import { transformTicketDownloads } from '~esav/api/transformers/ticketDownloads/transformTicketDownloads';

interface ResultType {
    booking: Booking | undefined;
    products: Products;
    ticketDownloads: TicketDownloads;
    refundables: Refundables;
}

type ApiResponseType = ApiSchema['BookingResponse'];
type ParamsType = object;

/**
 * Loads a booking by its id and transforms it for the eSAV view.
 *
 * Based on the type of booking, the result will re-create all its details
 * (passengers, products with offers, ticket downloads, refundables).
 */
export default async function apiLoadEsavBooking(id: string): Promise<ApiResult<ResultType>> {
    try {
        return await callApiGet<ResultType, ApiResponseType, ParamsType>(
            `/bookings/${id}`,

            // Request Params
            {},

            // Transform the result
            (response: ApiResponseType) => {
                const booking = transformBooking(response);
                const products = transformBookingProducts(response);
                const ticketDownloads = transformTicketDownloads(response, products);
                const refundables = transformRefundables(response, products);

                return {
                    booking,
                    products,
                    ticketDownloads,
                    refundables,
                } satisfies ResultType;
            },
        );
    }
    catch (error: any) {
        handleError(error, {
            id,
        });

        // Return an empty object if an error occurs
        return {
            ok: false,
            warnings: [],
            data: {
                booking: undefined,
                products: {},
                ticketDownloads: {},
                refundables: {},
            } satisfies ResultType,
        };
    }
}
