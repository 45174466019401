/**
 * Esav Store
 * ----------------------------
 *
 */
export const useEsavStore = defineStore('esav', () => {
    const refundStore = useRefundStore();

    // Keep the transformed objects from the loaded booking
    const products = ref<Products>({});
    const ticketDownloads = ref<TicketDownloads>({});
    const refundables = ref<Refundables>({});

    /**
     * Ticket Downloads
     */
    // Does the booking contain any valid ticket downloads?
    const canDownloadTickets = computed(() => {
        return Object.values(ticketDownloads.value).flat().length > 0
            && Object.values(ticketDownloads.value).flat().some(ticketDownload => ticketDownload.canDownload);
    });

    /**
     * Refundables
     */
    // Does the booking contain any items eligible for refund?
    const canRefund = computed(() =>
        Object.values(refundables.value)
            .flat()
            .some(refundable => refundStore.isRefundEligible(refundable)),
    );

    // Does the booking contain any refundable insurances
    const hasRefundableInsurance = computed(() => Object.values(refundables.value).flat().some(refundable => refundable.type === 'insurance'));

    /**
     * Booking
     * ------------------------------------------------------------------------------------------
     */
    const booking = ref<Booking>();

    async function loadBooking(id: string) {
        let ok: boolean;
        ({
            ok,
            data: {
                booking: booking.value,
                products: products.value,
                ticketDownloads: ticketDownloads.value,
                refundables: refundables.value,
            },
        } = await apiLoadEsavBooking(id));

        return ok;
    }

    /**
     * ------------------------------------------------------------------------------------------
     */

    function reset() {
        booking.value = undefined;
        products.value = {};
        ticketDownloads.value = {};
        refundables.value = {};
    }

    return {
        booking,
        loadBooking,

        products,
        ticketDownloads,
        canDownloadTickets,
        refundables,
        canRefund,
        hasRefundableInsurance,

        reset,
    };
}, {
    // Esav Booking is not persisted! Reload on every fresh page load
    // persist: storePersist.storePersistOptions,
});
