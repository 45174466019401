import { isAfter } from 'date-fns';

/**
 * Transforms the refundable amcillaries from the booking and offer data.
 * Ancillary refundables may only be to display and are not really refundable (e.g. insurance)
 *
 * @param booking - The booking data from the API schema.
 * @param offer - The booked offer data from the API schema.
 * @returns An array of transformed ancillary refundables.
 */
export function transformRefundablesAncillary(
    booking: ApiSchema['Booking'],
    offer: ApiSchema['BookedOffer'],
): RefundableAncillary[] {
    const refundables: RefundableAncillary[] = [];
    // First, get all fulfillments
    // where the bookingPart contains the Ancillary id of this offer
    const fulfilledAncillaries = booking.fulfillments?.filter(
        fulfillment => fulfillment.bookingParts?.some(
            bookingPart => offer.ancillaries?.some(
                ancillary => ancillary.id === bookingPart.id,
            ),
        ),
    );

    // Skip if no fulfilled ancillary offers were found
    if (!fulfilledAncillaries || fulfilledAncillaries.length === 0) {
        return [];
    }

    // Then, loop through all these fulfilled ancillaries
    fulfilledAncillaries?.forEach((fulfillment) => {
        // Get the Ancillary Object
        const ancillary = offer.ancillaries
            ?.find(ancillary => fulfillment.bookingParts?.some(bookingPart => bookingPart.id === ancillary?.id));

        // The ancillary belongs to another offer
        // We're going to add the ancillary to that product group
        // For now, we get the product key through the referenced id ih the bookingPartRefs
        const referencedBookingPartId = ancillary?.bookingPartRefs?.[0]?.id;

        // For now, we only look for referenced offers within the "activities"
        const referencedActivity = booking?.bookedOffers
            ?.flatMap(offer => offer.activities || [])
            .find(activity => activity.id === referencedBookingPartId);

        if (!referencedActivity) {
            return;
        }

        // If we have the referenced activity, get the product group key
        const activityProductConfig = getActivityProductConfigById(referencedActivity?.activityId);

        // Abort if none was found
        if (!activityProductConfig) {
            return;
        }

        const isOfferExpired = isAfter(zurichDate(), zurichDate(referencedActivity?.validUntil));

        // Determine if the product is still eligible for refund
        const isRefundExpired = false;
        const isRefundDisabled = ancillary?.type === 'INSURANCE';
        let refundDeadline;

        if (ancillary?.type === 'INSURANCE') {
            refundables.push({
                id: fulfillment.id,
                productKey: activityProductConfig.key,
                isOfferExpired,
                type: 'insurance',
                name: ancillary?.summary ?? '',
                status: ancillary?.status ?? 'FULFILLED',
                price: ancillary?.price ?? { amount: 0, currency: 'CHF' },
                refundDeadline,
                isRefundExpired,
                isRefundDisabled,
                groupPerPassenger: false,
            } satisfies RefundableAncillary);
        }
    });

    return refundables;
}
