/* eslint-disable no-console */
import * as Sentry from '@sentry/vue';
import { FetchError } from 'ofetch';

export function handleError(
    error: any,
    context: Record<string, unknown> = {},
    toastType: false | 'generic' | 'specific' = 'generic',
) {
    const rootError: FetchError = error.cause as Error ?? error;
    let genericMessage = 'core.errorMessages.500';
    const serializedContext = JSON.parse(JSON.stringify(context));

    if (rootError instanceof FetchError) {
        genericMessage = 'core.errorMessages.apiGeneric';
        handleAPIError(rootError, serializedContext);
    }
    else {
        handleGenericError(error, serializedContext);
    }

    // Make sure loading overlay is hidden
    const { isLoading } = useLoading();
    isLoading.value = false;

    // Either no toast message is shown, a generic one or the actual technical error message
    // Make sure to not expose technical details to the user, if not necessary
    if (toastType) {
        const { setErrorToast } = useToasts();
        const { t: $t, te: $te } = useNuxtApp().$i18n;
        if (toastType === 'generic') {
            setErrorToast($te(genericMessage) ? $t(genericMessage) : genericMessage);
        }
        else {
            setErrorToast(error.message);
        }
    }
}

/**
 * Handles API errors by logging them to the console and Sentry.
 * Logging important API request infos
 *
 * @param error - The API error to handle.
 * @param context - Additional context information for the error.
 */
function handleAPIError(
    error: FetchError,
    context: Record<string, unknown> = {},
) {
    const apiTraceId = error?.response?.headers?.get('x-traceid');
    if (import.meta.dev) {
        console.error('API Error:', error);
        console.debug('Details', error?.data?.detail);
        console.debug('Status', error?.data?.status);
        console.debug('API Trace ID', apiTraceId);
        console.debug('Detail Context', context);
    }

    try {
        Sentry.withScope((scope) => {
            scope.setContext('API Error Details', {
                'Endpoint': error?.data?.instance,
                'Title': error?.data?.title,
                'Details': error?.data?.detail,
                'Status': error.data?.status,
                'API Trace Id': apiTraceId,
            });
            scope.setContext('Detail Context', context);

            error.message = `API Error: ${error.message}`;
            Sentry.captureException(error);
        });
    }
    catch (error) {
        console.error('Error while logging to Sentry', error);
    }
}

/**
 * Handles API errors by logging them to the console and Sentry.
 * Logging important API request infos
 *
 * @param error - The API error to handle.
 * @param context - Additional context information for the error.
 */
function handleGenericError(
    error: any,
    context: Record<string, unknown> = {},
) {
    if (error.statusCode === 404) {
        return;
    }

    if (import.meta.dev) {
        console.error('Generic Error:', error);
        console.debug('Detail Context', context);
    }

    try {
        Sentry.withScope((scope) => {
            scope.setContext('Detail Context', context);
            Sentry.captureException(error);
        });
    }
    catch (error) {
        console.error('Error while logging to Sentry', error);
    }
}

/**
 * Handles Datatrans payment errors.
 * This includes regular failing payments and 3D secure errors.
 */
export function handleDatatransPaymentError(error: Error) {
    const { setErrorToast } = useToasts();
    const { t: $t } = useNuxtApp().$i18n;
    setErrorToast($t('core.errorMessages.paymentGeneric'));

    if (import.meta.dev) {
        console.error('Payment Datatrans Error', error);
    }
}
