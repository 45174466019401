import { endOfDay, isAfter } from 'date-fns';

/**
 * Retrieves hotel fulfillment parts from the booking and creates downloadable vouchers (PDFs).
 *
 * @param booking - The booking data from the API schema.
 * @param product - The previously transformed hotel product
 * @returns An array of transformed ticket downloads
 */
export function transformTicketDownloadsHotel(
    booking: ApiSchema['Booking'],
    product: ProductHotel,
): TicketDownloadHotel[] {
    const ticketDownloads: TicketDownloadHotel[] = [];
    const offer = booking.bookedOffers?.find(o => o.offerId === product.offer?.offerId);

    // Next, get all fulfillments of the booking that correspond to accommodation
    const fulfilledAccommodations = booking?.fulfillments
        // Only look at the entries in fulfillments which correspond to an accommodation offer
        // By looking at the ids within the booking parts of the fulfillment
        // and then checking if those ids correspond to an accommodation — an id within the accommodations of an offer
        ?.filter(fulfillment => fulfillment.bookingParts?.some(
            bookingPart => offer?.accommodations?.some(accommodation => accommodation.id === bookingPart.id),
        ));

    // Loop through the filtered accommodation fulfillments
    fulfilledAccommodations?.forEach((fulfillment) => {
        // Get the accommodation based on the id of the fulfillments' booking parts
        const accommodation = offer?.accommodations?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));

        // Downloadable if fulfilled (not refunded) and still in valid timeframe (cant download tickets from past)
        const isOfferExpired = isAfter(zurichDate(), endOfDay(zurichDate(accommodation?.validUntil)));
        const canDownload = accommodation?.status === 'FULFILLED' && !isOfferExpired;

        const documents = fulfillment.fulfillmentDocuments?.map((document) => {
            return {
                download: document.downloadLink ?? '',
                format: document.format,
                type: document.type,
            };
        }) ?? [];

        ticketDownloads.push({
            id: fulfillment.id,
            type: product.config.type,
            status: accommodation?.status ?? 'FULFILLED',
            canDownload,
            isOfferExpired,
            product,
            name: accommodation?.summary ?? '', // Room name
            details: {
                id: accommodation?.accommodationDetails?.id ?? '',
                name: accommodation?.accommodationDetails?.name ?? '', // Hotel name
            },
            documents,
        } satisfies TicketDownloadHotel);
    });

    return ticketDownloads;
}
