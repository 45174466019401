import { isAfter } from 'date-fns';

/**
 * Transforms the refundable hotels from the booking and offer data.
 *
 * @param booking - The booking data from the API schema.
 * @param product - The previously transformed hotel product
 * @returns An array of transformed hotel refundables
 */
export function transformRefundablesHotel(
    booking: ApiSchema['Booking'],
    product: ProductHotel,
): RefundableHotel[] {
    const refundables: RefundableHotel[] = [];
    const offer = booking.bookedOffers?.find(o => o.offerId === product.offer?.offerId);

    // Next, get all fulfillments of the booking that correspond to accommodation
    const fulfilledAccommodations = booking?.fulfillments
        // Only look at the entries in fulfillments which correspond to an accommodation offer
        // By looking at the ids within the booking parts of the fulfillment
        // and then checking if those ids correspond to an accommodation — an id within the accommodations of an offer
        ?.filter(fulfillment => fulfillment.bookingParts?.some(
            bookingPart => offer?.accommodations?.some(accommodation => accommodation.id === bookingPart.id),
        ));

    // Loop through the filtered accommodation fulfillments
    fulfilledAccommodations?.forEach((fulfillment) => {
        // Get the accommodation based on the id of the fulfillments' booking parts
        const accommodation = offer?.accommodations?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));

        // Determine if the product is still eligible for refund
        const isOfferExpired = isAfter(zurichDate(), zurichDate(accommodation?.validUntil));
        const isRefundDisabled = false;
        let isRefundExpired = false;
        let refundDeadline;

        if (accommodation?.afterSaleConditions && accommodation.afterSaleConditions.length > 0) {
            // Find the first condition where the 'condition' is 'REFUND'
            const refundCondition = accommodation.afterSaleConditions.find(
                condition => condition.condition === 'REFUND',
            );

            // Check if the current date is before the 'validUntil' date and after the 'validFrom' date
            isRefundExpired = !refundCondition
            || (refundCondition?.validUntil !== null && isAfter(zurichDate(), zurichDate(refundCondition?.validUntil)))
            || (refundCondition?.validFrom !== null && isAfter(zurichDate(refundCondition?.validFrom), zurichDate()));

            refundDeadline = zurichDate(refundCondition?.validUntil);
        }

        // Finally, transform and add the object to the refunds
        refundables.push({
            id: fulfillment.id,
            type: 'hotel',
            status: accommodation?.status ?? 'FULFILLED',
            product,
            isOfferExpired,
            name: accommodation?.summary ?? '', // Room name
            details: {
                id: accommodation?.accommodationDetails?.id ?? '',
                name: accommodation?.accommodationDetails?.name ?? '', // Hotel name,
            },
            price: accommodation?.price ?? { amount: 0, currency: 'CHF' },
            refundDeadline,
            isRefundExpired,
            isRefundDisabled,
            groupPerPassenger: false,
        } satisfies RefundableHotel);
    });

    return refundables;
}
