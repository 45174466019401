/*
 * This file is used to import all the product configurations and export them as an array.
 *
 * The configs are used in the api transformations
 * as well as in the steps of the offer flow (?).
 */

export type ProductConfigs = Record<ProductKey, ProductConfig>;

const context: Record<string, { default: ProductConfig }> = import.meta.glob('./configProduct*.ts', { eager: true });
export const configProducts = Object.values(context).map<ProductConfig>(c => c.default);
export default configProducts;

export const configProductsKeyed = configProducts.reduce((acc: ProductConfigs, pc: ProductConfig) => {
    acc[pc.key] = pc;
    return acc;
}, {} as ProductConfigs);
