/**
 * Checks all offers from a booking and re-creates "products" from them.
 * Right now, there is one product per offer and this is determined by the existence of certain properties.
 * e.g.
 * - if there are "activities" in the offer, it is an "activity" product.
 * - if there are "accommodations" in the offer, it is a "hotel" product.
 * - if there are "admissions" or "reservations" in the offer, it is a "p2p" product.
 * - etc.
 *
 *
 * @param response The full api response
 * @returns all products as "Products"
 */
export function transformBookingProducts(response: ApiSchema['BookingResponse']): Products {
    const booking = response.booking;
    const bookedOffers = booking?.bookedOffers;

    // Return empty object if the booking or the offers are empty
    if (!booking || !bookedOffers) {
        return {};
    }

    // Loop through all the offers in the response
    return bookedOffers?.reduce<Products>((acc, offer) => {
        // Activity Product
        if (offer.activities?.length) {
            const product = transformBookingProductActivity(offer, booking);
            add(acc, product?.config.key, product);
        }

        // P2P Product
        else if (offer.admissions?.length || offer.reservations?.length) {
            const product = transformBookingProductP2P(offer, booking);
            add(acc, product?.config.key, product);
        }

        // Hotel Product
        else if (offer.accommodations?.length) {
            const product = transformBookingProductHotel(offer, booking);
            add(acc, product?.config.key, product);
        }

        return acc;
    }, {}) ?? {};
}

/**
 *
 */
function add(acc: Products, key: ProductKey | undefined, product: Product | undefined) {
    if (!key || !product) {
        return;
    }
    acc[product.config.key] = product;
}
