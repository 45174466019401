import { endOfDay, isAfter } from 'date-fns';

/**
 * Retrieves activity fulfillment parts from the booking and creates downloadable vouchers (PDFs + Apple PKPasses).
 * The used fulfillmentOption (keycard) is stored so downloads can be grouped by e.g. Voucher/Swisspass
 *
 * @param booking - The booking data from the API schema.
 * @param product - The previously transformed activity product
 * @returns An array of transformed ticket downloads
 */
export function transformTicketDownloadsActivity(
    booking: ApiSchema['Booking'],
    product: ProductActivity,
): TicketDownloadActivity[] {
    const ticketDownloads: TicketDownloadActivity[] = [];
    const offer = booking.bookedOffers?.find(o => o.offerId === product.offer?.offerId);

    // Next, get all fulfillments of the booking that correspond to activities
    const fulfilledActivities = booking?.fulfillments
        // Only look at the entries in fulfillments which correspond to an activity offer
        // By looking at the ids within the booking parts of the fulfillment
        // and then checking if those ids correspond to an activity — an id within the activities of an offer
        ?.filter(fulfillment => fulfillment.bookingParts?.some(
            bookingPart => offer?.activities?.some(activity => activity.id === bookingPart.id),
        ));

    fulfilledActivities?.forEach((fulfillment) => {
        // Get the activity based on the id of the fulfillments' booking parts
        const activity = offer?.activities?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));
        const passenger = booking?.passengers.find(p => activity?.passengerIds?.includes(p.id));

        let keycard: KeycardType;
        let keycardReference;

        // Downloadable if fulfilled (not refunded) and still in valid timeframe (cant download tickets from past)
        const isOfferExpired = isAfter(zurichDate(), endOfDay(zurichDate(activity?.validUntil)));
        const canDownload = activity?.status === 'FULFILLED' && !isOfferExpired;

        const swisspassFulfilmentPart = fulfillment.fulfillmentParts?.find(
            fp => fp.securityFeatures?.some(sf => sf.type?.type === 'SWISSPASS_REFERENCE'),
        );

        if (swisspassFulfilmentPart) {
            keycard = 'SWISSPASS';
            keycardReference = swisspassFulfilmentPart.controlId ?? '';
        }
        else {
            keycard = 'PDF_A4';
            keycardReference = fulfillment.fulfillmentParts?.[0]?.controlId ?? '';
        }

        ticketDownloads.push({
            id: fulfillment.id,
            type: product.config.type,
            status: activity?.status ?? 'FULFILLED',
            canDownload,
            isOfferExpired,
            product,
            passenger: {
                ref: passenger?.externalRef ?? '',
                firstName: passenger?.detail?.firstName ?? '',
                lastName: passenger?.detail?.lastName ?? '',
                dateOfBirth: zurichDate(passenger?.dateOfBirth),
            },
            keycard,
            keycardReference,
            documents: fulfillment.fulfillmentDocuments?.map((d) => {
                return {
                    download: d.downloadLink ?? '',
                    format: d.format,
                    type: d.type,
                };
            }) ?? [],
        } satisfies TicketDownloadActivity);
    });

    return ticketDownloads;
}
