import { startOfDay } from 'date-fns';

/**
 * Extracts a P2P product from a given booking offer
 *
 * @param bookedOffer The offer containing the P2P data
 * @param booking - The booking data from the API schema.
 * @returns The P2P product
 */
export function transformBookingProductP2P(
    bookedOffer: ApiSchema['BookedOffer'],
    booking: ApiSchema['Booking'],
): ProductP2P | undefined {
    const passengers = booking.passengers;

    const offer = transformBookingOfferP2P(bookedOffer, passengers);

    // TODO: Filter only active trips once we have exchanges. But the problem is we dont want
    // to filter out refunded trips (which would also not be part of activeTripIds), else
    // the "getP2PProductConfig" would not work as it doesnt have a valid trip up anymore
    const trips = booking.trips
        // Sort trips by start time as the API does not guarantee the order
        ?.sort((a, b) => zurichDate(a.startTime).getTime() - zurichDate(b.startTime).getTime())
        // Only include trips that are not cancelled (refunded, exchanged)
        // ?.filter(trip => booking.activeTripIds?.length
        //     ? booking.activeTripIds?.includes(trip.id)
        //     : true);
        ?? [];

    const tripUp = trips[0] ? transformTrip(trips[0]) : undefined;
    const tripDown = trips[1] ? transformTrip(trips[1]) : undefined;
    const productConfig = getP2PProductConfig(tripUp?.destination, offer.hasEPR);

    if (!tripUp || !productConfig) {
        return undefined;
    }

    let passengerRefs: string[] = [];
    if (!bookedOffer.admissions?.length) {
        // EPR Only
        passengerRefs = passengers.filter(p => bookedOffer.reservations?.some(r => r.passengerIds.includes(p.id))).map(p => p.externalRef);
    }
    else {
        // Normal
        passengerRefs = passengers.filter(p => bookedOffer.admissions?.some(a => a.passengerIds.includes(p.id))).map(p => p.externalRef);
    }

    const dateFrom = startOfDay(tripUp.departureTime);
    const isRoundtrip = !!tripDown;

    return {
        config: productConfig,
        dateFrom,
        passengerRefs,
        origin: tripUp.origin,
        destination: tripUp.destination,
        isRoundtrip,
        tripUp,
        tripDown,
        offer,
    } satisfies ProductP2P;
}
