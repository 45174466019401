/**
 * ESav Refund Store
 * ----------------------------
 *
 */
export const useRefundStore = defineStore('refund', () => {
    const refundOffers: Ref<RefundOffer[]> = ref([]);
    const refundCompletedOffers: Ref<RefundCompletedOffer[]> = ref([]);

    function isRefundEligible(refundable: Refundable) {
        return !refundable.isRefundExpired
            && !refundable.isOfferExpired
            && !refundable.isRefundDisabled
            && refundable.status !== 'REFUNDED';
    }

    /**
     * ------------------------------------------------------------------------------------------
     */

    function reset() {
        refundOffers.value = [];
        refundCompletedOffers.value = [];
    }

    return {
        refundOffers,
        refundCompletedOffers,

        isRefundEligible,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
