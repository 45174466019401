/**
 * Flow Store
 * ----------------------------
 *
 * The flow stores holds data of the current "booking flow", the user is engaged in.
 * The flow controls a sequence of pages and available products/options to buy.
 */
export const useFlowStore = defineStore('flow', () => {
    const localeRoute = useLocaleRoute();
    const { t: $t } = useNuxtApp().$i18n;
    const router = useRouter();

    // If user is currently in the upsell flow
    const isUpsellFlow = ref(false);

    // The main flow product config
    const productConfigMain = ref<ProductConfig>();

    // The upsell flow product config
    const productConfigUpsell = ref<ProductConfig>();

    // The current product config (main or upsell)
    const productConfigCurrent = computed(() => isUpsellFlow.value ? productConfigUpsell.value : productConfigMain.value);

    // The steps (routes/pages) for the current product config (main or upsell steps)
    const stepsCurrent = computed(() => (isUpsellFlow.value ? productConfigCurrent.value?.stepsUpsell : productConfigCurrent.value?.steps) ?? []);

    // The main flow route to redirect back to after upsell flow
    const routeMainFlow = computed(() => {
        const upsellStep = productConfigMain.value?.steps.find(step => step.isUpsellStep);
        return upsellStep ? localeRoute(upsellStep.routeName) : getFirstFlowRoute(productConfigMain.value?.steps);
    });

    const currentStep = computed(() => getCurrentStep(stepsCurrent.value, router.currentRoute.value));
    const currentStepIndex = computed(() => getCurrentStepIndex(stepsCurrent.value, router.currentRoute.value));
    const previousStep = computed(() => getPreviousFlowStep(stepsCurrent.value, router.currentRoute.value));
    const nextStep = computed(() => getNextFlowStep(stepsCurrent.value, router.currentRoute.value));
    const previousRoute = computed(() => previousStep.value ? localeRoute(previousStep.value?.routeName) : undefined);

    // Returns the goto route for the footer next button
    const nextRoute = computed(() => {
        if (nextStep.value) {
            return localeRoute(nextStep.value?.routeName);
        }
        else {
            return localeRoute({ name: 'checkout-step-1-cart' });
        }
    });

    // Returns the label for the footer next button
    const nextLabel = computed(() => {
        if (currentStep.value?.nextLabel) {
            return $t(currentStep.value.nextLabel);
        }
        else if (nextStep.value) {
            $t('core.buttons.next');
        }
        else {
            return $t('core.buttons.toCart');
        }
    });

    /**
     * Reset
     */
    function reset() {
        productConfigMain.value = undefined;
        productConfigUpsell.value = undefined;
        isUpsellFlow.value = false;
    }

    return {
        productConfigMain,
        productConfigUpsell,
        productConfigCurrent,
        isUpsellFlow,

        stepsCurrent,
        routeMainFlow,

        currentStep,
        currentStepIndex,
        previousStep,
        nextStep,
        previousRoute,
        nextRoute,
        nextLabel,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
