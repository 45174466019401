import { transformTicketDownloadsActivity } from '~esav/api/transformers/ticketDownloads/transformTicketDownloadsActivity';
import { transformTicketDownloadsHotel } from '~esav/api/transformers/ticketDownloads/transformTicketDownloadsHotel';
import { transformTicketDownloadsP2P } from '~esav/api/transformers/ticketDownloads/transformTicketDownloadsP2P';

/**
 * Transform a booking response and return the downloadble tickets.
 *
 * @param response The full api response
 * @param products The already transformed products object
 * @returns The ticket downloads as "TicketDownloads"
 */
export function transformTicketDownloads(response: ApiSchema['BookingResponse'], products: Products): TicketDownloads {
    const booking = response.booking;
    const bookedOffers = booking?.bookedOffers;

    // Return empty object if the booking or the offers are empty
    if (!booking || !bookedOffers) {
        return {};
    }

    return Object.values(products).reduce<TicketDownloads>((acc, product) => {
        if (product.config.type === 'activity') {
            const ticketDownloads = transformTicketDownloadsActivity(booking, product as ProductActivity);
            add(acc, product.config.key, ticketDownloads);
        }
        else if (product.config.type === 'p2p') {
            const ticketDownloads = transformTicketDownloadsP2P(booking, product as ProductP2P);
            add(acc, product.config.key, ticketDownloads);
        }
        else if (product.config.type === 'hotel') {
            const ticketDownloads = transformTicketDownloadsHotel(booking, product as ProductHotel);
            add(acc, product.config.key, ticketDownloads);
        }
        return acc;
    }, {}) ?? {};
}

/**
 *
 */
function add(acc: TicketDownloads, key: ProductKey | undefined, ticketDownloads: TicketDownload[]) {
    if (!key || !ticketDownloads.length) {
        return;
    }
    if (!acc[key]) {
        acc[key] = [];
    }
    acc[key].push(...ticketDownloads);
}
