export interface Toast {
    key: string;
    type: string;
    message: string;
    duration: number;
    data?: any;
    flash?: boolean;
}

/**
 * Composable to show global error/info toast messages
 */
export default function useToasts() {
    const toasts = useState<Record<string, Toast>>('toasts', () => ({}));

    /**
     * Adds a toast message
     * Does not remove other toasts. Multiple toasts can be added at the same time, with different keys.
     * Use same key to replace
     * @param key Key for toast, to replace existing toast with same key
     * @param type Info or Error
     * @param message Message to show
     * @param duration Duration in ms to show the toast, 0 for infinite
     * @param flash If true, toast will be removed on next page load
     * @param data Additional data for custom toasts
     */
    function addToast(key: string = 'info', type: 'info' | 'error' | 'countdown' = 'info', message: string = '', duration: number = 0, flash = true, data?: any) {
        toasts.value[key] = { key, type, message, duration, flash, data };
    }

    /**
     * Add error toast (and replace previous error toast)
     */
    function setErrorToast(message: string = '', duration: number = 0, flash = true) {
        addToast('error', 'error', message, duration, flash);
    }

    /**
     * Add info toast (and replace previous info toast)
     */
    function setInfoToast(message: string = '', duration: number = 0, flash = true) {
        addToast('info', 'info', message, duration, flash);
    }

    /**
     * Remove all toasts
     */
    function reset() {
        toasts.value = {};
    }

    /**
     * Remove all flash toasts
     */
    function resetFlash() {
        toasts.value = Object.fromEntries(
            Object.entries(toasts.value).filter(([, toast]) => !toast.flash),
        );
    }

    /**
     * Reset info toasts only
     */
    function resetInfoToast() {
        toasts.value = Object.fromEntries(
            Object.entries(toasts.value).filter(([, toast]) => toast.key !== 'info'),
        );
    }

    return {
        toasts,
        addToast,
        setErrorToast,
        setInfoToast,
        reset,
        resetFlash,
        resetInfoToast,
    };
}
