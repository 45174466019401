export default function useConfirmDialogState() {
    const isVisible = useState<any>('confirm_dialog_is_visible', () => false);
    const heading = useState('confirm_dialog_heading', () => '');
    const body = useState('confirm_dialog_body', () => '');
    const confirmCallback = useState<any>('confirm_dialog_confirm_callback', () => undefined);
    const confirmLabel = useState('confirm_dialog_confirm_label', () => '');
    const cancelCallback = useState<any>('confirm_dialog_cancel_callback', () => undefined);
    const cancelLabel = useState('confirm_dialog_cancel_label', () => '');

    return {
        isVisible,
        heading,
        body,
        confirmLabel,
        confirmCallback,
        cancelCallback,
        cancelLabel,

    };
}
