/**
 * Extracts an hotel offer from a given booked offer (from a booking)
 * @param bookedOffer The offer containing the hotel
 * @param passengers All passengers in the booking
 * @returns The hotel offer
 */
export function transformBookingOfferHotel(
    bookedOffer: ApiSchema['BookedOffer'],
    passengers: ApiSchema['Passenger'][],
): OfferHotel | undefined {
    const offerPassengers = passengers.filter(p => bookedOffer.activities?.some(a => a.passengerIds.includes(p.id))) ?? [];
    const passengerRefs = offerPassengers.map(p => p.externalRef);
    const offerParts = bookedOffer.accommodations?.map((accommodation) => {
        const passengerRefs = passengers.filter(p => accommodation?.passengerIds.includes(p.id)).map(p => p.externalRef) ?? [];
        return {
            type: 'hotel',
            passengerRefs,
            name: accommodation.summary ?? '',
            price: accommodation.price,
        } satisfies OfferPartHotel;
    }) ?? [];

    return {
        offerId: bookedOffer.offerId,
        type: 'hotel',
        passengerRefs,
        offerParts,
    } satisfies OfferHotel;
}
