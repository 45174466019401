import { endOfDay, isAfter } from 'date-fns';

/**
 * Retrieves p2p fulfillment parts from the booking and creates downloadable vouchers (PDFs + Apple PKPasses).
 *
 * @param booking - The booking data from the API schema.
 * @param product - The previously transformed p2p product
 * @returns An array of transformed ticket downloads
 */
export function transformTicketDownloadsP2P(
    booking: ApiSchema['Booking'],
    product: ProductP2P,
): TicketDownloadP2P[] {
    const ticketDownloads: TicketDownloadP2P[] = [];
    const offer = booking.bookedOffers?.find(o => o.offerId === product.offer?.offerId);

    booking?.passengers.forEach((passenger) => {
        const admissions = offer?.admissions?.filter(a => a.passengerIds?.includes(passenger.id));
        const reservations = offer?.reservations?.filter(r => r.passengerIds?.includes(passenger.id));

        let status: ApiSchema['BookingPartStatus'] = 'FULFILLED';
        const isAdmissionsRefunded = admissions?.every(a => a.status === 'REFUNDED');
        const isReservationsRefunded = reservations?.every(a => a.status === 'REFUNDED');
        if (isAdmissionsRefunded && isReservationsRefunded) {
            status = 'REFUNDED';
        }

        const isAdmissionsExpired = admissions?.every(a => isAfter(zurichDate(), endOfDay(zurichDate(a?.validUntil)))) ?? false;
        const isReservationsExpired = reservations?.every(r => isAfter(zurichDate(), endOfDay(zurichDate(r?.validUntil)))) ?? false;
        const isOfferExpired = isAdmissionsExpired && isReservationsExpired;
        const canDownload = status === 'FULFILLED' && !isOfferExpired;

        const documents = booking?.documents
            ?.filter(document => document.type === 'COMPOSITE_QR_CODE')
            ?.filter(document => document.passengerRef === passenger.id);

        ticketDownloads.push({
            id: `p2p-${product.config.key}-${passenger.id}`,
            type: product.config.type,
            status,
            canDownload,
            isOfferExpired,
            product,
            passenger: {
                ref: passenger?.externalRef ?? '',
                firstName: passenger?.detail?.firstName ?? '',
                lastName: passenger?.detail?.lastName ?? '',
                dateOfBirth: zurichDate(passenger?.dateOfBirth),
            },
            documents: documents
                ?.map((d) => {
                    return {
                        download: d.downloadLink ?? '',
                        format: d.format ?? '',
                        type: d.type,
                    };
                }) ?? [],
        } satisfies TicketDownloadP2P);
    });

    return ticketDownloads;
}
