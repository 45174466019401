/**
 * Extracts an activity offer from a given booked offer (from a booking)
 * @param bookedOffer The offer containing the activity
 * @param passengers All passengers in the booking
 * @returns The activity offer
 */
export function transformBookingOfferActivity(
    bookedOffer: ApiSchema['BookedOffer'],
    passengers: ApiSchema['Passenger'][],
): OfferActivity | undefined {
    const offerPassengers = passengers.filter(p => bookedOffer.activities?.some(a => a.passengerIds.includes(p.id))) ?? [];
    const passengerRefs = offerPassengers.map(p => p.externalRef);
    const offerParts = bookedOffer.activities?.map((activity) => {
        const offerPartPassengers = passengers.filter(p => activity?.passengerIds.includes(p.id)) ?? [];
        const passenger = offerPartPassengers[0];
        return {
            type: 'activity',
            passengerRefs: passenger?.externalRef ? [passenger.externalRef] : [],
            name: activity.summary ?? 'Aktivität',
            passengerName: `${passenger?.detail?.firstName} ${passenger?.detail?.lastName}`,
            price: activity.price,
        } satisfies OfferPartActivity;
    }) ?? [];

    return {
        offerId: bookedOffer.offerId,
        type: 'activity',
        passengerRefs,
        offerParts,
    } satisfies OfferActivity;
}
