interface OnetrustScript {
    OptanonWrapper: () => void;
    OnetrustActiveGroups: string;
    OneTrust: any;
}

interface GTMState {
    gtmReady: boolean;
}

declare global {
    interface Window extends OnetrustScript, GTMState {}
}

export default defineNuxtPlugin(() => {
    try {
        const runtimeConfig = useRuntimeConfig();

        if (!runtimeConfig.public.onetrust.enabled || !runtimeConfig.public.onetrust.key || !runtimeConfig.public.gtm.id) {
            return;
        }

        // GTM Script
        // only loaded after Cookie Consent
        const { load: loadGTM, onLoaded: onLoadedGTM } = useScriptGoogleTagManager({
            id: runtimeConfig.public.gtm.id,
            scriptOptions: {
                trigger: 'manual',
            },
        });

        onLoadedGTM(() => {
            window.gtmReady = true;

            // Page View
            useScriptEventPage(({ title, path }) => {
                analyticsTrackPageView(title, path, false);
            });

            const route = useRoute();
            analyticsTrackPageView(document.title, route.fullPath, true);
        });

        // OneTrust Script, used to load GTM after Cookie Consent and show Cookie Banner
        useScript({
            'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
            'data-domain-script': runtimeConfig.public.onetrust.key,
        });

        // Load GTM after Cookie Consent
        window.OptanonWrapper = function () {
            const consentGroups = ['C0004', 'C0002'];
            if (consentGroups.some(group => window.OnetrustActiveGroups?.includes(group))) {
                loadGTM();
            }
        };
    }
    catch (error) {
        handleError(error, {}, false);
    }
});
