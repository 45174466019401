/**
 * Extracts an p2p offer from a given booked offer (from a booking)
 * @param bookedOffer The offer containing the p2p
 * @param passengers All passengers in the booking
 * @returns The p2p offer
 */
export function transformBookingOfferP2P(
    bookedOffer: ApiSchema['BookedOffer'],
    passengers: ApiSchema['Passenger'][],
): OfferP2P {
    const tripIds = bookedOffer.tripCoverage?.coveredTripIds ?? [];
    const isFirstClass = bookedOffer.admissions?.every(a => a.travelClass === 'FIRST') ?? false;
    const isDiscounted = false;
    const {
        hasEPR,
        isEPRRequired,
        isEPRAvailable,
        offerParts,
        passengerRefs,
    } = transformOfferReservations(bookedOffer, passengers);

    return {
        offerId: bookedOffer.offerId,
        type: 'p2p',
        tripIds,
        passengerRefs,
        offerParts,
        hasEPR,
        isEPRAvailable,
        isEPRRequired,
        isFirstClass,
        isDiscounted,
        eprLegIds: [],
    } satisfies OfferP2P;
}

/**
 * TODO: Probably booking offers never have reservations inside admissions so we can simplify this?
 * check if also applies in checkout
 */
function transformOfferReservations(bookedOffer: ApiSchema['BookedOffer'], passengers: ApiSchema['Passenger'][]) {
    let hasEPR: boolean | 'only';
    let isEPRRequired: boolean;
    let isEPRAvailable: boolean;
    let admissionOfferParts: OfferPartAdmission[];
    let reservationOfferParts: OfferPartReservation[];
    let passengerRefs: string[] = [];

    // EPR Only
    if (!bookedOffer.admissions?.length && bookedOffer.reservations?.length) {
        isEPRRequired = true;
        hasEPR = 'only';
        isEPRAvailable = true;
        admissionOfferParts = [];
        reservationOfferParts = bookedOffer.reservations?.map(reservation => transformOfferPartReservation(reservation, passengers)) ?? [];
        passengerRefs = passengers.filter(p => bookedOffer.reservations?.some(r => r.passengerIds.includes(p.id))).map(p => p.externalRef);
    }
    // Has admissions and OPTIONAL reservations
    else if (bookedOffer.reservations?.length) {
        isEPRRequired = false;
        hasEPR = true;
        isEPRAvailable = true;
        admissionOfferParts = bookedOffer.admissions?.map(admission => transformOfferPartAdmission(admission, passengers)) ?? [];
        reservationOfferParts = bookedOffer.reservations?.map(reservation => transformOfferPartReservation(reservation, passengers)) ?? [];
        passengerRefs = passengers.filter(p => bookedOffer.admissions?.some(a => a.passengerIds.includes(p.id))).map(p => p.externalRef);
    }
    // Has MANDATORY reservations
    else if (bookedOffer.admissions?.some(a => a.isReservationRequired)) {
        isEPRRequired = true;
        hasEPR = true;
        isEPRAvailable = true;
        admissionOfferParts = bookedOffer.admissions?.map(admission => transformOfferPartAdmission(admission, passengers)) ?? [];
        reservationOfferParts = [];
        passengerRefs = passengers.filter(p => bookedOffer.admissions?.some(a => a.passengerIds.includes(p.id))).map(p => p.externalRef);
    }
    // Has NO reservations (only admissions)
    else {
        isEPRRequired = false;
        hasEPR = false;
        isEPRAvailable = false;
        admissionOfferParts = bookedOffer.admissions?.map(admission => transformOfferPartAdmission(admission, passengers)) ?? [];
        reservationOfferParts = [];
        passengerRefs = passengers.filter(p => bookedOffer.admissions?.some(a => a.passengerIds.includes(p.id))).map(p => p.externalRef);
    }

    const offerParts = [
        ...admissionOfferParts,
        ...reservationOfferParts,
    ];

    return {
        hasEPR,
        isEPRRequired,
        isEPRAvailable,
        offerParts,
        passengerRefs,
    };
}

/**
 *
 */
function transformOfferPartReservation(reservation: ApiSchema['Reservation'], passengers: ApiSchema['Passenger'][]): OfferPartReservation {
    const offerPartPassengers = passengers.filter(p => reservation?.passengerIds.includes(p.id)) ?? [];
    const passenger = offerPartPassengers[0];
    return {
        id: reservation.id,
        type: 'reservation',
        passengerRefs: passenger?.externalRef ? [passenger.externalRef] : [],
        name: reservation.summary ?? 'Reservation',
        passengerName: `${passenger?.detail?.firstName} ${passenger?.detail?.lastName}`,
        price: reservation.price,
    } satisfies OfferPartReservation;
}

/**
 *
 */
function transformOfferPartAdmission(admission: ApiSchema['Admission'], passengers: ApiSchema['Passenger'][]): OfferPartAdmission {
    const offerPartPassengers = passengers.filter(p => admission?.passengerIds.includes(p.id)) ?? [];
    const passenger = offerPartPassengers[0];

    return {
        type: 'admission',
        passengerRefs: passenger?.externalRef ? [passenger.externalRef] : [],
        name: admission.summary ?? 'Ticket',
        passengerName: `${passenger?.detail?.firstName} ${passenger?.detail?.lastName}`,
        price: admission.price,
        isEPRRequired: admission.isReservationRequired ?? false,
    };
}
