/**
 * Helper composable to show global loading state
 */
export default function useLoading() {
    const isLoading = useState<boolean>('loading_state', () => false);

    return {
        isLoading,
    };
}
