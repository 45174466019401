export default {
    key: 'swp',
    type: 'activity',
    name: 'core.productNames.swp',
    activityId: '3cd1d151-f212-4045-a33d-168e47b202e9',
    isUsingReductionCards: false,
    isUsingKeyCards: true,
    upsells: ['hotel'],

    steps: [
        {
            routeName: 'offerFlow-swp-steps-step-1-date',
            name: 'offerFlow.pages.swp.steps.step1',
        },
        {
            routeName: 'offerFlow-swp-steps-step-2-passengers',
            name: 'offerFlow.pages.swp.steps.step2',
        },
        {
            routeName: 'offerFlow-swp-steps-step-3-summary',
            name: 'offerFlow.pages.swp.steps.step3',
            isUpsellStep: true,
        },
    ],
} satisfies ProductConfigActivity;
