/**
 * All Pinia store state is persisted to local storage via the Pinia Persisted State Plugin.
 * This plugin makes sure that stale data is removed after a certain time of inactivity.
 */
export default defineNuxtPlugin(() => {
    try {
        if (!localStorage.getItem('tot_last_interaction')) {
            localStorage.setItem('tot_last_interaction', zurichDate().toISOString());
        }
        const lastInteraction = zurichDate(localStorage.getItem('tot_last_interaction') ?? '');
        if (!lastInteraction) {
            return;
        }

        const diff = zurichDate().getTime() - lastInteraction.getTime();
        const diffInMinutes = diff / 1000 / 60;
        const EXPIRY_IN_MINUTES = 60;

        if (diffInMinutes > EXPIRY_IN_MINUTES) {
            localStorage.clear();
        }
    }
    catch (error) {
        try {
            localStorage.clear();
        }
        catch (error) {}
        handleError(error, {}, false);
    }
});
